<template>
  <div class="page container p-4">
    <div class="columns">
      <div class="column is-offset-3-tablet is-6-tablet">
        <div class="has-text-centered">
          <img
            class="anon-avatar"
            src="@/assets/img/anon-avatar.png"
            alt="regístrate"
          >
        </div>
        <b-field>
          <b-input
            ref="firstname"
            v-model="form.firstname"
            type="text"
            placeholder="Nombre"
            required
            maxlength="100"
            @blur="validateForm"
          />
        </b-field>
        <b-field>
          <b-input
            ref="lastname"
            v-model="form.lastname"
            type="text"
            placeholder="Apellidos"
            required
            maxlength="100"
            @blur="validateForm"
          />
        </b-field>
        <b-field>
          <b-input
            ref="email"
            v-model="form.username"
            type="email"
            placeholder="Email"
            required
            maxlength="100"
            @blur="validateForm"
          />
        </b-field>
        <b-field>
          <b-input
            ref="password"
            v-model="form.password"
            type="password"
            placeholder="Contraseña"
            required
            maxlength="20"
            password-reveal
            @blur="validateForm"
          />
        </b-field>
        <b-field>
          <b-input
            ref="repeatPassword"
            v-model="form.repeat_password"
            type="password"
            placeholder="Repita contraseña"
            required
            maxlength="20"
            @blur="validateForm"
          />
        </b-field>
        <b-field>
          <multiselect
            v-model="selectedSchool"
            track-by="title"
            label="title"
            deselect-label="No se puede deseleccionar"
            select-label="Pulsa para seleccionar"
            placeholder="Selecciona escuela"
            selected-label="Seleccionada"
            :options="schools"
            :searchable="true"
            :allow-empty="false"
          />
          <div
            v-if="schoolError"
            class="help is-danger"
          >
            {{ schoolError }}
          </div>
        </b-field>
        <b-field
          ref="activationCode"
          label="¿Tienes un código de activación? Introdúcelo en el siguiente campo."
        >
          <b-input
            v-model="form.method_code"
            type="text"
            placeholder="Código de activación"
            maxlength="50"
            @blur="validateForm"
          />
        </b-field>
        <b-field>
          <b-checkbox
            ref="privacyPolicy"
            v-model="form.legal_accepted"
            required
            @input="legalInputChanged"
          >
            <b-button
              class="px-0"
              type="is-text"
              @click="openLegalModal"
            >
              Acepto la política de privacidad
            </b-button>
          </b-checkbox>
          <div
            v-if="legalError"
            class="help is-danger"
          >
            {{ legalError }}
          </div>
        </b-field>
        <div class="mt-3">
          <b-button
            type="is-primary is-uppercase"
            expanded
            @click="register"
          >
            Regístrate
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LegalContent from '@/assets/legal/privacy-policy.json'
import Multiselect from 'vue-multiselect'
export default {
  title: 'Registro',
  name: 'Register',
  components: {Multiselect},
  data: function () {
    return {
      isValid: false,
      schools: [],
      selectedSchool: null,
      schoolError: null,
      legalError: null,
      form: {
        firstname: '',
        lastname: '',
        username: '',
        password: '',
        repeat_password: '',
        school: null,
        method_code: '',
        legal_accepted: false
      },
      legalText: LegalContent
    }
  },
  watch: {
    selectedSchool(school){
      this.form.school = parseInt(school.id)
      if(this.schoolError){
        this.schoolError = null
      }
    }
  },
  async mounted() {
    const params = this.$route.query
    await this.loadSchools()
    if (params.hasOwnProperty('school_id') && params.school_id) {

      this.selectedSchool = this.schools.find(school => {
        return parseInt(school.id) === parseInt(params.school_id)
      })
    }
  },
  methods: {
    validateForm() {
      const validateFields = ["firstname", "lastname", "email", "password", "repeatPassword"];
      let isValidFormData = true
      let schoolValidation = true
      let legalValidation = true
      this.schoolError = null

      validateFields.forEach((item) => {
        if (this.$refs.hasOwnProperty(item)) {
          let itemIsValid = this.$refs[item].checkHtml5Validity()

          if (item === 'repeatPassword') {
            itemIsValid = (this.form.password === this.form.repeat_password)
          }

          isValidFormData = isValidFormData && itemIsValid
        }
      })

      if(!this.form.school){
        this.schoolError = 'Completa este campo'
        schoolValidation = false
      }

      if(!this.form.legal_accepted){
        this.legalError = 'Debes aceptar nuestra política de privacidad'
        legalValidation = false
      }

      this.isValid = isValidFormData && schoolValidation && legalValidation
    },
    async loadSchools() {
      try {
        await this.$store.dispatch('initLoading')
        this.schools = await this.$api.school.getList()
        await this.$store.dispatch('finishLoading')
      } catch (error) {
        await this.$store.dispatch('finishLoading')
      }
    },
    async register() {
      this.validateForm()

      if (this.isValid && this.form.legal_accepted === true) {

        this.$buefy.dialog.confirm({
          title: 'Confirma tu escuela',
          message: `Vas a solicitar el registro en la escuela <b>${this.selectedSchool.title}</b>, ¿Deseas continuar?`,
          confirmText: 'Continuar',
          cancelText: 'Volver',
          onConfirm: () => this.doRegistration()
        })
      }
    },
    async doRegistration(){
      try {
        const userData = {
          firstname: this.form.firstname,
          lastname: this.form.lastname,
          email: this.form.username,
          password: this.form.password,
          school: this.form.school,
          method_code: this.form.method_code
        }

        await this.$store.dispatch('initLoading')
        await this.$api.user.register(userData)
        await this.$store.dispatch('finishLoading')

        this.$buefy.dialog.alert({
          title: 'Registro',
          message: 'Te has registrado correctamente.<br/><br/>En breve TU ESCUELA validará tu registro y la APP Musicaeduca.online estará operativa.',
          confirmText: 'Cerrar'
        })

        await this.$router.push('/')
      } catch (error) {
        await this.$store.dispatch('finishLoading')
        const data = error.response.data
        this.$msg.error(data.message)
      }
    },
    openLegalModal() {
      const currentYear = ''+(new Date()).getFullYear()
      this.$buefy.dialog.alert({
        title: this.legalText.title,
        message: this.legalText.content.split('{currentYear}').join(currentYear),
        confirmText: 'Cerrar'
      })
    },
    legalInputChanged(value){
      if(value && this.legalError){
        this.legalError = ''
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.multiselect {
  .multiselect__tags{
    box-shadow: inset 0 0.0625em 0.125em rgb(10 10 10 / 5%);
  }
}
</style>
